import './App.scss';
import radio_render from "./basic.png"
import diagram from "./diagram.svg"

function Card({title, children, ...props}) {
    return (
        <div className="card" {...props}>
              {title && <h2 className="card__title">
                  {title}
              </h2>}
              <p className="card__text">
                  {children}
              </p>
          </div>
    )
}

function App() {
  return (
      <>
          <div className="header header--brand" id="top_header">
              <h1>Foxhunter</h1>
              <p>EMSO Swiss Army Knife</p>
          </div>

          <div className="radio_intro">
              <img className="radio_intro__radio" src="/gen1.png" />
              <div className="radio_intro__cards">
                  <Card>
                      An ATAK-integrated, COTS, EMSO Swiss Army Knife to decieve, detect, and deny
                  </Card>
                  <Card>
                      Foxhunter exposes a multitude of RF capabilities and integrate into existing systems, to reduce distractions, training, and weight burdens on the warfighter
                  </Card>
                  <a className="radio_intro__cards__break" href="#genai_header">Skip to Foxhunter GenAI → </a>

                  <Card title="End-User & Mission First">
                      Foxhunter was inspired by off-the-record conversations between an engineer an enlisted 17E & 25E Soldiers at an EMSO testing & evaluation event.<br/>
                  </Card>

                  <Card title="SWaP-C Optimized">
                      <ul style={{paddingLeft: "1rem", margin: "0"}}>
                          <li>Less than $3,000 (incl. software license)</li>
                          <li>Less than 350 grams</li>
                          <li>Fits in a pocket (without antennas)</li>
                          <li>COTS</li>
                          <li>NDAA compliant electronics</li>
                      </ul>
                  </Card>
              </div>
          </div>

          <div className="header header--soft swissarmy_graphic__title">
              <h1>EA & SIGINT at your fingertips</h1>
          </div>
          <div className="nuggets nuggets--san">
              <div className="nugget">
                  <object type="image/svg+xml" data="/trl4.svg"></object>
                  TRL 4
              </div>
          </div>
          <div className="swissarmy_graphic">
              <object type="image/svg+xml" data="/gen1_diagram.svg"></object>
          </div>

          <div className="header header--soft" id="genai_header">
              <h1>Foxhunter <i>GenAI</i></h1>
              <p></p>
          </div>



          <div className="nuggets nuggets--genai">
              <div className="nugget">
                  <object type="image/svg+xml" data="/trl3.svg"></object>
                  Experimental
              </div>
          </div>

          <div className="foxai__container">
              <div className="foxai">
                  <div className="foxai__heading">GenAI</div>
                  <div className="foxai__subtext">Generative AI Waveform</div>
              </div>
          </div>
          <Card title="Counter-AI LPI/LPD" id="card1">
              Using the <b>same underlying technology behind deepfakes, clone your adversary’s waveforms</b>. Deceive their classification systems and EW personnel into leaving your communications alone.
          </Card>

          <video controls id="first-video" >
              <source src="/firstvid.mp4" type='video/mp4' />
              <source src="/firstvid.webm" type='video/webm'/>
              <track default kind="captions" srcLang="en" src="/firstvidcaptions.vtt" />
          </video>
          <Card title="Edge AI + Software-Defined Radio" id="card2">
              Foxhunter's integrated, hardware-agnostic edge signal processing solution, built for man portable, attritable, and low-SWaP-C radios makes it an excellent candidate for the next breakthrough in Generative AI LPI/LPD experimentation
          </Card>
          <object type="image/svg+xml" id="diagram" data={diagram}></object>
          <Card title="Warfighters First" id="warfighters-first">
              Foxhunter is designed to expose a multitude of RF capabilities and integrate into existing systems, to reduce distractions, training, and weight burdens on the warfighter. Our team is dedicated to our end-users.
              <br/><br/>
              Dhruv [<a href="mailto:dhruv@foxhunter.ai">dhruv@foxhunter.ai</a>]<br/>
              Troy [<a href="mailto:troy@foxhunter.ai">troy@foxhunter.ai</a>]<br/>
          </Card>
      </>
  );
}

export default App;
